import React, { useState } from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryLabel, PrimaryTextInput, PrimaryButton, Logo1, ErrorMsg, } from "../../globalComponents";
import { StyledDiv, PageWrapper, FormsContainer, Forms, ForgotPassword, StyledDivDesktop, PageWrapperDesktop } from "./styles";


import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/auth.context";
import { useWhitelabel } from "../../context/whitelabel.context";
import LoadingMain from "../Loadings/LoadingMain";
import { useEffect } from "react";


const CardLogin = () => {
    const {isMobile, errorMsg, setErrorMsg, setIsLoading} = useControles()
    const {Login, statusWizard, GetBoolWizard, setAuthStatus, authStatus} = useAuth()
    const {clienteId, primaryBackgroundHex, logoVertical} = useWhitelabel()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    const handleKeyPress = (event) => {
        
        if (event.key === 'Enter' || event.code === 'Enter') {
            handleLogin();
        }
    }

    const handleLogin = async () => {
        if(!email){
            setErrorMsg("Insira seu email.")
        } else if (!senha){
            setErrorMsg("Insira sua senha.")
        } else {
            setErrorMsg(null)
            const userData = {
                id: 0,
                nome: '',
                email: email,
                username: '',
                senha: senha,
                clienteId: clienteId
            }
            
             

            await Login(userData).then((response)=>{
                console.log(response.data.wizardBool, ": response login")
                setErrorMsg(null)
                
                setAuthStatus(true)
                if(response.data.wizardBool == 1){
                    navigate('/')
                }

            }).catch((err)=>{
                console.log(err.response.data.message, " : catch")
                setErrorMsg(err.response.data.message)
            })
           
        }
        
    }

    useEffect(()=>{
        if(authStatus){
            if(statusWizard == '1' || statusWizard == 1){
                navigate('/')
                setAuthStatus(true)
            } else {
                navigate('/')
                setAuthStatus(true)
            }
        }
        
    }, [statusWizard])

    return (
        <>
            {isMobile ? 
                <>
                <StyledDiv>
                    <PageWrapper>

                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 150, maxHeight: 150}}/>
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 0, paddingBottom: 30,}}>
                            <h1 style={{fontSize: 36}}>Bem vindo!</h1>
                            <p>Entre para continuar</p>
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Email</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu email"
                                    value={email} onChange={(e)=>setEmail(e.target.value)} onKeyDown={handleKeyPress}/>
                                </div>
                                
                                <div>
                                    <PrimaryLabel>Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua senha" type="password"
                                    value={senha} onChange={(e)=>setSenha(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>
                                <ForgotPassword onClick={()=>{
                                    navigate('/ForgotPassword')
                                }}>
                                    Esqueceu sua senha?
                                </ForgotPassword>
                            </Forms>

                            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                
                                <PrimaryButton onClick={handleLogin}>Entrar</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5,}}>
                                    <p>Caso não tenha uma conta, </p>
                                        <span onClick={()=>{
                                            navigate('/Register')
                                        }}>  faça seu cadastro!</span>
                                </div>
                                
                                
                            </div>
                           

                            
                        </FormsContainer>
                    </PageWrapper>
                </StyledDiv>
                </> : 
                <>
                <StyledDivDesktop>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',    height: '100%' ,backgroundImage: 'var(--Background-image)', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 200, maxHeight: 200}}/>
                    </div>

                    <PageWrapperDesktop>

                        
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, paddingBottom: 30,}}>
                            <h1 style={{fontSize: 30, textAlign: 'left', maxWidth: '420px'}}>
                                Estamos te esperando
                                para mais uma rodada!
                            </h1>
                            
                            <p style={{textAlign: 'left'}}>Insira seus dados e comece a diversão!</p>
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div onKeyDown={handleKeyPress}>
                                    <PrimaryLabel>Email</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu email"
                                    value={email} onChange={(e)=>setEmail(e.target.value)}
                                    onKeyDownCapture={handleKeyPress}/>
                                </div>
                                
                                <div>
                                    <PrimaryLabel>Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua senha" type="password"
                                    value={senha} onChange={(e)=>setSenha(e.target.value)} onKeyDown={handleKeyPress}/>
                                </div>
                                <ForgotPassword onClick={()=>{
                                    navigate('/ForgotPassword')
                                }}>
                                    Esqueceu sua senha?
                                </ForgotPassword>
                            </Forms>

                            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                            
                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton onClick={handleLogin}>Entrar</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5,}}>
                                    <p>Caso não tenha uma conta, </p>
                                        <span onClick={()=>{
                                            navigate('/Register')
                                        }}>  faça seu cadastro!</span>
                                </div>
                                
                                
                            </div>
                           

                            
                        </FormsContainer>
                    </PageWrapperDesktop>
                </StyledDivDesktop>
                </>
            }
        </>
    )
}

export default CardLogin